import Header from "../Header";
const UserFind = () => {
  return (
    <userfind>
      <Header />
      <div>
        <p>내 주변 카페 찾기 페이지 입니다.</p>
      </div>
    </userfind>
  );
};
export default UserFind;
