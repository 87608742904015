import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const ManagerReservation = () => {
  const [Cdate, setDate] = useState(new Date());
  return (
    <managerreservation>
      <div>
    
  

      </div>
    </managerreservation>
  );
};
export default ManagerReservation;
