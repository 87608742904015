const ManagerCafeInfo = () => {
  return (
    <managercafeinfo>
      <div>
        <p>카페 정보 관리 페이지 입니다.</p>
      </div>
    </managercafeinfo>
  );
};
export default ManagerCafeInfo;
